import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/agent/home-data`);
  },

  async addStudent(payload) {
    let response = await axiosInstance().post(`/agent/add-student`, payload);

    return response;
  },

  async saveStudentProfile(payload) {
    const { student_user_id } = payload;

    let response = await axiosInstance().post(`/agent/save-student-profile/${student_user_id}`, payload);

    return response;
  },

  async removeStudentDocument(payload) {
    const { student_user_id, sd_mapper_id } = payload;

    let response = await axiosInstance().post(`/agent/remove-student-doc/${student_user_id}/${sd_mapper_id}`);

    return response;
  },

  async removeStudentVisaDocument(payload) {
    const { student_user_id, svd_id } = payload;

    let response = await axiosInstance().post(`/agent/remove-student-visa-doc/${student_user_id}/${svd_id}`);

    return response;
  },

  async applyForStudent(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(`/agent/apply-course-student/${student_user_id}/${course_id}`, payload);

    return response;
  },

  async cancelApplicationForStudent(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(`/agent/cancel-application-student/${student_user_id}/${course_id}`);

    return response;
  },

  async acceptSuggestionForStudent(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(`/agent/accept-suggestion/${student_user_id}/${course_id}`);

    return response;
  },

  async rejectSuggestionForStudent(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(`/agent/reject-suggestion/${student_user_id}/${course_id}`);

    return response;
  },

  async commissionSwitchAuth(payload) {
    let response = await axiosInstance().post(`/agent/commission-switch-auth`, payload);

    return response;
  },

  // async marketingCollateral() {
  //   return await axiosInstance().get(`/agent/marketing-collateral`);
  // },

  async getFranchiseIndustryNews() {
    return await axiosInstance().get(`/agent/industry-news`);
  },

  async getFranchiseMaterial() {
    return await axiosInstance().get(`/agent/material`);
  },

  async getQuizResult() {
    return await axiosInstance().get(`/agent/training/quiz-result`);
  },

  async getQuizResponses() {
    return await axiosInstance().get(`/agent/quiz-responses`);
  },
};
